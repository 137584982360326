<template>
  <v-container
    class="blue-grey lighten-5"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-row>
          <v-toolbar
            dense
            height="50"
            color="grey"
            dark
            class="rounded-lg"
          >
            <h3>Consulta de General de órdenes</h3>
            <v-spacer />

            <v-btn
              text
              @click="mostrarfiltrar"
            >
              Filtrar
            </v-btn>

            <v-col cols="3">
              <v-text-field
                v-model="search"
                class="mx-2"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-col>

            <v-btn
              class="mx-2"
              color="success"
              @click="consultar"
            >
              Consultar
            </v-btn>
          </v-toolbar>
        </v-row>
        
        <v-row>
          <v-col>
            <v-card min-width="700">
              <v-data-table
                dense
                :headers="headers"
                :items="misordenes"
                :search="search"
                is-mobile="mobile"
                @dblclick:row="editar"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.idorden }}</td>
                    <td>
                      {{ item.nomcont }}
                      {{ item.empresa }}
                    </td>

                    <td>{{ item.titulo }}</td>
                    <td>{{ item.obs }}</td>
                    
                    <td>{{ item.problema }}</td>
                    <td>{{ item.fecha }}{{ item.hrcreada }}</td>
                    <td>{{ item.telefono }} {{ item.email }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col
            v-if="verfiltrar"
            cols="3"
          >
            <v-card
              class="rounded-lg"
            >
              <filtros />
              <v-row justify="end">
                <v-btn
                  id="boton"
                  text
                  small
                  class="mr-4 boton"
                  @click="cerrarfiltrar"
                >
                  Ocultar
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import filtros from '@/views/crm/ordenes/filtros'
export default {
  components:{
    filtros
  },
  data () {
    return {
      mobie:true,
      ocultarfiltrar: false,
      verfiltrar: false,
      search: '',
      misordenes: [],
      headers: [
        { text: 'Orden', value: 'idorden' },
        // { text: 'Urgencia', value: 'prioridad' },
        { text: 'Contacto', value: 'modulo' },
        { text: 'Orden', value: 'titulo' },
        { text: 'Problema', value: 'obs' },
        { text: 'Actividad', value: 'obs' },
        
        { text: 'Fecha', align: 'start', value: 'substr(fecha,6,10)'},
        { text: 'Email / Telefono', value: 'obs' },
      ],

    }
  },

  computed: {
    ...mapGetters('login',['getdatosUsuario'])
  },

  created(){
    this.consultar()
  },
 
  methods: {
      
    consultar(){
      // limpiar
      this.misordenes= []
      // console.log(this.getdatosUsuario)

      let id = this.getdatosUsuario.idusuariosweb
      console.log('consultar usuario id', id)

      this.$http.get("v2/ordenes.list" ).then(response=>{
        if (response.data.error===null){
          console.log(response)
          this.misordenes = response.data.result

          response.data.result.forEach( element => {
            element.fecha = element.fecha.substr(0,10)

          })
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
          
    mostrarfiltrar(){
      this.verfiltrar = true
    },

    cerrarfiltrar(){
      this.verfiltrar = false
    },

   
    editItem (event, {item}) {
      console.log(item)
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      
    },
    // ver(item){
    //   console.log("orden", item.idorden)
    //   this.verOrden(item.idordenes)
    // },

    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'addorden', params: {addorden:item}})
    },
  }
}

</script>


<style scoped>
  .boton{
    top: -7px;
  }

  
</style>