<template>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar
          height="30"
          elevation="0"
        >
          <h2>Filtrar</h2>
        </v-toolbar>
        <v-text-field
          label="Buscar contacto"
          hide-details
        />
        <v-text-field
          hide-details
          label="Buscar cliente"
        />
        <v-text-field
          label="Buscar empresa"
          hide-details
        />
        <v-text-field
          label="Proyecto"
          hide-details
        />
        <v-text-field
          label="Poliza de soporte"
        />
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fecha1"
              label="Fecha 1"
              dense
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="fecha1"
            locale="es"
            @input="menu1 = false"
            @change="consultar"
          />
        </v-menu>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fecha2"
              dense
              label="Fecha 2"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="fecha2"
            locale="es"
            @input="menu2 = false"
            @change="consultar"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    fecha1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    fecha2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
  }),
  created(){
    let date0 = new Date()
    let mes = date0.getMonth()
    let anio = date0.getFullYear()
    // console.log(mes, anio)
    this.fecha1 = new Date(anio, mes, 1).toISOString().substr(0, 10)

    this.consultar()
  },
}
</script>